"use client";

import { Fragment } from "react";

import clsx from "clsx";

import styles from "./AnygiftPoints.module.scss";
import { egiftPointItems } from "../consts";

export function AnygiftPoints(): React.ReactNode {
  return (
    <div className={styles.points}>
      <div className={styles.points}>
        {egiftPointItems.map((point) => (
          <Fragment key={point.id}>
            <div className={clsx(styles.point, "bg__white text__center")}>
              <img src={point.icon} alt={point.title} />
              <p
                className={clsx(styles.pointTitle, "text__s text__bold text__center mg__top__s")}
                dangerouslySetInnerHTML={{ __html: point.title }}
              />
            </div>
            {point.id == 2 && <div className={styles.wrap}></div>}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
