export function pathnameWithQueryParams(
  baseUrl: string,
  params: Record<string, string | number | boolean>
) {
  const url = new URL(baseUrl, window.location.origin);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value.toString());
  });

  return url.pathname + url.search;
}
