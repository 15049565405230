import clsx from "clsx";

import { Column } from "@/components/containers";
import { NextImageLP } from "@/components/displays";

import ImgNeeds from "$/images/lp/form03/needs.png";
import ImgGraphBbChocolateH from "$/images/lp/graph_bb_chocolate_h.png";
import ImgGraphBbChocolateV from "$/images/lp/graph_bb_chocolate_v.png";

import styles from "./HealthEssentials.module.scss";

export interface HealthEssentialsProps {
  showAnnotation?: boolean;
}

export function HealthEssentials({
  showAnnotation = true,
}: HealthEssentialsProps): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <p className="text__bold text__xl text__center">
            からだに必要なもの、
            <span className="wsnr">全部入り</span>
          </p>
        </div>
        <div className="col-12 col-m-8 col-offset-m-2 text__center mg__top__l">
          <NextImageLP src={ImgNeeds} alt="からだに必要なもの、全部入り" width={460} />
          <p className="text__xl text__bold mg__top__l">
            など
            <span className={clsx(styles.proteinMarkpen, "text__xxl__pc")}> 約30種類 </span>の
            <span className={clsx(styles.proteinMarkpen, "text__xxl__pc")}> 栄養素 </span>
            が
            <br />
            すべてはいった
            <span className="wsnr">完全栄養食</span>
            です。
            <sup className="sup">※1</sup>
          </p>
        </div>
        <div className="col-12 col-m-10 col-offset-m-1">
          <div
            className={clsx("bg__white", "text__center", "mg__top__xl", styles.proteinNutrition)}
          >
            <p className={clsx("text__l", "text__bold", styles.proteinNutritionTitle)}>
              BASE BREAD
              <sup className="sup">
                <i className="far fa-registered fa-fw" />
              </sup>
              <span className="wsnr">︎の栄養素</span>
            </p>
            <p className="text__center mg__top__m mg__bottom__m">
              1食2袋あたりの推奨摂取量との<span className="wsnr">各栄養素比較 ※</span>
            </p>
            <Column align="center">
              <NextImageLP
                src={ImgGraphBbChocolateH}
                alt="1食あたりの推奨摂取量との各栄養素比較"
                width={640}
                className={clsx("clear__sp")}
              />
              <NextImageLP
                src={ImgGraphBbChocolateV}
                alt="1食あたりの推奨摂取量との各栄養素比較"
                width={340}
                className={clsx("clear__pc")}
              />
            </Column>
            <div className="row">
              <div className="col-12 col-m-10 col-offset-m-1">
                <p
                  className={clsx(
                    "text__s",
                    "text__gray__dark",
                    "text__justify",
                    "mg__top__m",
                    styles.proteinNote
                  )}
                >
                  ※推定値
                  ※栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合
                  ※BASE BREAD ︎チョコレートの場合
                </p>
              </div>
            </div>
          </div>
          {showAnnotation && (
            <p className="text__s text__gray__dark mg__top__l">
              ※1
              1食分（2袋）で、栄養素等表示基準値に基づき、脂質・飽和脂肪酸・炭水化物・ナトリウム以外のすべての栄養素で1日分の基準値の1/3以上を含む。
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
