"use client";
import { memo, useEffect } from "react";

import { initLetroWidget } from "@/utils/letro";

export interface LetroSectionProps {
  letroId: string;
  maxWidth?: number;
}

export const LetroSection = memo(function LetroSection({
  letroId,
  maxWidth,
}: LetroSectionProps): React.ReactNode {
  useEffect(initLetroWidget, [letroId]);
  return (
    <div key={letroId} style={{ maxWidth }}>
      <div data-gadget-id={letroId} className="letroUgcsetGadget" data-contents="letro-gadget" />
    </div>
  );
});
