import clsx from "clsx";
import Image, { ImageProps } from "next/image";

import styles from "./NextImageResponsive.module.scss";

export interface NextImageResponsiveProps extends ImageProps {}

export function NextImageResponsive({
  className,
  alt,
  src,
  ...restProps
}: NextImageResponsiveProps): React.ReactNode {
  return <Image alt={alt} src={src} className={clsx(styles.image, className)} {...restProps} />;
}
