"use client";

import React from "react";

import clsx from "clsx";
import { Link, LinkProps } from "react-scroll";

import { Image } from "@/components/displays/Image";

import cta from "$/images/egift/cta.png";

import styles from "./AnygiftCtaLink.module.scss";

interface AnygiftCtaLinkProps extends Omit<LinkProps, "ref"> {}

export function AnygiftCtaLink({ children, ...rest }: AnygiftCtaLinkProps) {
  return (
    <Link className={clsx(styles.ctaLink, "clear__pc")} smooth {...rest}>
      <span className="text__m text__bold">{children}</span>
      <Image alt="eギフト画像" src={cta.src} aspectRatio="15 / 4" />
    </Link>
  );
}
