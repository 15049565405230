import { productNameSkuMap } from "@/configs/skuDB";
import { ProductOptionModel } from "@/models/product/type";

import ImgSet1 from "$/images/lp/invite/set1.png";
import ImgSet2 from "$/images/lp/invite/set2.png";
import ImgSet3 from "$/images/lp/invite/set3.png";

export const INVITE_START_SET: ProductOptionModel[] = [
  {
    name: "パン12袋セット",
    short_name: "パン 12袋",
    products: [
      { sku: productNameSkuMap.basebread_chocolate, quantity: 2 },
      { sku: productNameSkuMap.basebread_maple, quantity: 2 },
      { sku: productNameSkuMap.basebread_cinnamon, quantity: 2 },
      { sku: productNameSkuMap.basebread_koshian, quantity: 2 },
      { sku: productNameSkuMap.basebread_slice_raisin, quantity: 2 },
      { sku: productNameSkuMap.basebread_curry, quantity: 2 },
    ],
    images: [
      {
        src: ImgSet1,
        width: 268,
      },
    ],
  },
  {
    name: "パン8袋＆クッキー10袋セット",
    short_name: "パン＆クッキー 10袋",
    products: [
      { sku: productNameSkuMap.basebread_chocolate, quantity: 2 },
      { sku: productNameSkuMap.basebread_maple, quantity: 2 },
      { sku: productNameSkuMap.basebread_koshian, quantity: 2 },
      { sku: productNameSkuMap.basebread_slice_raisin, quantity: 2 },
      { sku: productNameSkuMap.basecookies_cocoa, quantity: 2 },
      { sku: productNameSkuMap.basecookies_earlgrey, quantity: 2 },
      { sku: productNameSkuMap.basecookies_coconut, quantity: 2 },
      { sku: productNameSkuMap.basecookies_pepper, quantity: 2 },
      { sku: productNameSkuMap.basecookies_cheese, quantity: 2 },
    ],
    images: [
      {
        src: ImgSet2,
        width: 268,
      },
    ],
  },
  {
    name: "パン16袋＆パンケーキミックス2袋セット",
    short_name: "パン＆パンケーキミックス 18袋",
    products: [
      { sku: productNameSkuMap.basebread_chocolate, quantity: 2 },
      { sku: productNameSkuMap.basebread_maple, quantity: 2 },
      { sku: productNameSkuMap.basebread_cinnamon, quantity: 2 },
      { sku: productNameSkuMap.basebread_koshian, quantity: 2 },
      { sku: productNameSkuMap.basebread_slice_plain, quantity: 2 },
      { sku: productNameSkuMap.basebread_slice_raisin, quantity: 2 },
      { sku: productNameSkuMap.basebread_rich, quantity: 2 },
      { sku: productNameSkuMap.basebread_curry, quantity: 2 },
      { sku: productNameSkuMap.basepancake_pancakemix, quantity: 2 },
    ],
    images: [
      {
        src: ImgSet3,
        width: 268,
      },
    ],
  },
];
