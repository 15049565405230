import clsx from "clsx";

import { NextImageResponsive } from "@/components/displays";

import ImgDoctor from "$/images/lp/doctor.png";

import styles from "./RecommendedMedical.module.scss";

export function RecommendedMedical(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <p className="text__bold text__xl text__center mg__bottom__l">医学専門家も推薦</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-m-5 col-offset-m-1">
          <NextImageResponsive src={ImgDoctor} alt="" width={517} />
          <p className="text__s">
            カリフォルニア大学 ロサンゼルス校（UCLA）
            <br />
            医学部 准教授
          </p>
          <p className="text__m text__bold mg__bottom__s mg__top__s">津川 友介</p>
          <p className="text__m text__justify mg__bottom__l mg__bottom__off">
            聖路加国際病院で医師として勤務した後、世界銀行を経て、ハーバード大学で修士号と博士号を取得。2017年より現職。著書に10万部突破のベストセラー『世界一シンプルで科学的に証明された究極の食事』（東洋経済新報社、2018年）がある。
          </p>
        </div>
        <div className="col-12 col-m-5">
          <div className={clsx(styles.proteinDoctor, "bg__white")}>
            <p className="text__justify mg__bottom__m">
              白米の食べすぎと塩分のとりすぎは日本人の不健康の二大要因ですが、BASE
              FOODはからだにいいと証明されている全粒穀物を使用しています。その全粒粉に加え、
              <span className={clsx(styles.proteinMarkpen, "text__bold")}>
                昆布や大豆など栄養価の高い原材料
              </span>
              がブレンドされたBASE
              FOODは、栄養バランスもととのっており、からだにいい主食だと考えられます。
            </p>
            <p className="text__justify">
              また、食事は、
              <span className={clsx(styles.proteinMarkpen, "text__bold")}>
                短距離走でなくマラソンです。
              </span>
              続けられなければ意味がなく、たとえば極端な糖質制限食は、短期的には効果があるものの、長期的には効果がないと報告されています。BASE
              FOODは、食事のなかで大きな割合を占める「主食」を置き換える形でむりなく継続できるため、健康や美容への効果が期待できます。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
