import { Column } from "@/components/containers";
import { NextImageLP } from "@/components/displays";

import ImgBenefitsMaru from "$/images/lp/benefit/benefits_maru.png";
import ImgHeadingMaru from "$/images/lp/heading_maru.jpg";
import ImgMerits from "$/images/lp/merits.png";
import ImgNotes from "$/images/lp/notes.png";

import { SetDetail } from "./SetDetail";

export function SetsSection(): React.ReactNode {
  return (
    <Column align="center" fullWidth>
      <NextImageLP
        src={ImgHeadingMaru}
        alt="BASE BREAD -ベースブレッド- 継続コース"
        // TODO: 微調整も消す
        style={{ marginBottom: -1 }}
      />
      {([14, 28, 46] as const).map((bags) => (
        <SetDetail key={bags} bags={bags} />
      ))}
      <NextImageLP src={ImgBenefitsMaru} width={750} alt="はじめての方限定 3つの初回特典" />
      <NextImageLP
        src={ImgMerits}
        alt="BASE BREAD継続コースは、4週間ごとにBASE BREADをお届けするコースです。初回は商品価格20%オフ、2回目以降10%オフの価格でご購入いただけます。"
      />
      <NextImageLP
        src={ImgNotes}
        alt="BASE BREAD継続コースは、4週間ごとにBASE BREADをお届けするコースです。初回は商品価格20%オフ、2回目以降10%オフの価格でご購入いただけます。"
        // TODO: 微調整も消す
        style={{ marginTop: -1 }}
      />
    </Column>
  );
}
