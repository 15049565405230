import clsx from "clsx";
import Image, { StaticImageData } from "next/image";

import styles from "./BackgroundImage.module.scss";

export type BackgroundImageProps<T extends React.ElementType> =
  React.ComponentPropsWithoutRef<T> & {
    as?: T;
    className?: string;
    spImageClassName?: string;
    bgImagePC: StaticImageData;
    bgImageSP: StaticImageData;
    children?: React.ReactNode;
  };

export function BackgroundImage<T extends React.ElementType = "div">(
  props: BackgroundImageProps<T>
): React.ReactNode {
  const {
    as: Component = "div",
    className,
    spImageClassName,
    bgImagePC,
    bgImageSP,
    children,
  } = props;

  return (
    <Component className={clsx(styles.root, className)}>
      {/* SP優先でSP側next/imageを使用し、PC側は固定のwebpファイルにする */}
      <picture>
        <source media="(min-width: 981px)" srcSet={bgImagePC.src} />
        {/* 981px以上の場合、ImgKvを使うため、ダウンロードサイズを抑えるため、イメージサイズを最小限にする */}
        <Image
          src={bgImageSP}
          alt="ベースフード"
          fill
          sizes="(max-width: 980px) 100vw, 1px"
          priority
          className={spImageClassName}
          style={{ objectFit: "cover" }}
        />
      </picture>
      {children}
    </Component>
  );
}
