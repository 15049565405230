import product_coffee_v1_0 from "$/images/lp/product_coffee.png";
import product_milk_v1_0 from "$/images/lp/product_milk.png";
import coffee_ura from "$/images/product/coffee/pkg_bb_coffee_ura.png";
import milk_ura from "$/images/product/milk/pkg_bb_milk_ura.png";

interface Product {
  id: string;
  src: string;
  alt: string;
  aspectRatio: string;
  detailSrc: string;
  detailAspectRatio: string;
}

export const lpProducts: Product[] = [
  {
    id: "chocolate",
    src: "https://asset.basefood.co.jp/lp/common/product_chocolate_v1_12.png",
    alt: "Chocolate",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_chocolate_v1_12_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "maple",
    src: "https://asset.basefood.co.jp/lp/common/product_maple_v1_6.png",
    alt: "Maple",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_maple_v1_6_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "koshian",
    src: "https://asset.basefood.co.jp/lp/common/product_koshian_v1_1.png",
    alt: "Koshian",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_koshian_v1_1_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "coffee",
    src: product_coffee_v1_0.src,
    alt: "Coffee",
    aspectRatio: "1005 / 376",
    detailSrc: coffee_ura.src,
    detailAspectRatio: "125 / 103",
  },
  {
    id: "milk",
    src: product_milk_v1_0.src,
    alt: "Milk",
    aspectRatio: "1005 / 376",
    detailSrc: milk_ura.src,
    detailAspectRatio: "125 / 103",
  },
  {
    id: "sliceRaisin",
    src: "https://asset.basefood.co.jp/lp/common/product_slice_raisin_v1_3.png",
    alt: "Raisin Slice",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_slice_raisin_v1_3_ura.png",
    detailAspectRatio: "980 / 995",
  },
  {
    id: "cinnamon",
    src: "https://asset.basefood.co.jp/lp/common/product_cinnamon_v1_6.png",
    alt: "Cinnamon",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_cinnamon_v1_6_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "curry",
    src: "https://asset.basefood.co.jp/lp/common/product_curry_v1_6.png",
    alt: "Curry",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_curry_v1_6_ura.png",
    detailAspectRatio: "980 / 807",
  },
  {
    id: "slicePlain",
    src: "https://asset.basefood.co.jp/lp/common/product_slice_plain_v1_5.png",
    alt: "Plain Slice",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_slice_plain_v1_5_ura.png",
    detailAspectRatio: "980 / 993",
  },
];
