"use client";

import { useCallback, useEffect, useMemo, useState } from "react";

import { CustomizeSetOption } from "@/components/domains/lp/OnePageCheckoutForm/SetSelector/CustomizeSetOption";
import { PanelSelector, PanelSelectorOption } from "@/components/inputs";
import type { ProductModel, ProductOptionModel } from "@/models/product/type";

import { ModalDialogCustomizeSet, ModalDialogCustomizeSetProps } from "./ModalDialogCustomizeSet";
import { SelectedProducts } from "./types";
import { transformRecommendSetProductToSelectOption } from "../../helpers";

export interface SetSelectorProps {
  products: ProductModel[];
  initialSelectIndex: number;
  initialSetProducts?: SelectedProducts;
  isInvited?: boolean;
  coupon?: string;
  onChange?: (selectedProducts: SelectedProducts, setIndex: number) => void;
  hideFreeSelect?: boolean;
  startSet: ProductOptionModel[];
}

export function SetSelector({
  products,
  initialSelectIndex,
  initialSetProducts,
  isInvited,
  onChange,
  hideFreeSelect,
  startSet,
}: SetSelectorProps): React.ReactNode {
  const [selectedRecommendSetIndex, setSelectedRecommendSetIndex] = useState(initialSelectIndex);
  const [selectedSetIndex, setSelectedSetIndex] = useState(initialSelectIndex);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [customizedSelectedProducts, setCustomizedSelectedProducts] =
    useState<ModalDialogCustomizeSetProps["initialSelectedProducts"]>();

  useEffect(() => {
    if (initialSelectIndex < startSet.length) {
      setSelectedRecommendSetIndex(initialSelectIndex);
    } else if (!hideFreeSelect) {
      // 「自分で選択する」＋「自分で選択するが有効」の場合のみ、カスタマイズ初期選択商品をセットする
      setCustomizedSelectedProducts(initialSetProducts);
    }

    // initialSelectIndexが推薦セット数以上の場合、「自分で選択する」として扱う
    setSelectedSetIndex(Math.min(initialSelectIndex, startSet.length));
  }, [hideFreeSelect, initialSelectIndex, initialSetProducts, startSet.length]);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const selectedProducts = useMemo(
    () =>
      transformRecommendSetProductToSelectOption(
        products,
        startSet[selectedRecommendSetIndex]?.products
      ),
    [products, selectedRecommendSetIndex, startSet]
  );

  const handleRecommendSetSelectChange = useCallback(
    (setIndex: number) => {
      setSelectedSetIndex(setIndex);
      if (setIndex < startSet.length) {
        setSelectedRecommendSetIndex(setIndex);

        const latestSelectedProducts = transformRecommendSetProductToSelectOption(
          products,
          startSet[setIndex]?.products
        );
        onChange?.(latestSelectedProducts, setIndex);
      }

      // 「自分で選択する」＋選択した商品がない場合、ダイアログを開く
      if (setIndex === startSet.length) {
        if (customizedSelectedProducts == null) {
          openDialog();
        } else {
          onChange?.(customizedSelectedProducts, setIndex);
        }
      }
    },
    [customizedSelectedProducts, onChange, openDialog, products, startSet]
  );

  const handleCancelCustomizeSet = useCallback(() => {
    if (customizedSelectedProducts == null) {
      setSelectedSetIndex(selectedRecommendSetIndex);
    }
    closeDialog();
  }, [closeDialog, customizedSelectedProducts, selectedRecommendSetIndex]);

  const handleApplyCustomizeSet = useCallback(
    (selectedProducts: SelectedProducts) => {
      setCustomizedSelectedProducts(selectedProducts);
      onChange?.(selectedProducts, startSet.length);
      closeDialog();
    },
    [closeDialog, onChange, startSet.length]
  );

  const setSelectorOptions = useMemo(() => {
    const options = startSet.map(({ name }, index) => ({
      title: name,
      value: index,
    })) as PanelSelectorOption<number>[];

    const selfSelectOption = {
      title: "自分で選択する",
      value: startSet.length,
      content: customizedSelectedProducts && (
        // 自分で選択する場合は、選択した商品を表示する
        <CustomizeSetOption {...{ customizedSelectedProducts, products, onClick: openDialog }} />
      ),
    };

    return hideFreeSelect ? options : options.concat(selfSelectOption);
  }, [customizedSelectedProducts, hideFreeSelect, openDialog, products, startSet]);

  return (
    <>
      <p className="mg__top__l mg__bottom__s text__xl text__bold text__yellow">セット数の選択</p>
      <PanelSelector
        value={selectedSetIndex}
        onChange={handleRecommendSetSelectChange}
        options={setSelectorOptions}
      />
      <ModalDialogCustomizeSet
        open={isDialogOpen}
        closeModal={closeDialog}
        products={products}
        onCancel={handleCancelCustomizeSet}
        onApply={handleApplyCustomizeSet}
        initialSelectedProducts={customizedSelectedProducts ?? selectedProducts}
        isInvited={isInvited}
      />
    </>
  );
}
