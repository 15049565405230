import clsx from "clsx";

import { Image } from "@/components/displays/Image";

import styles from "./Descriptions.module.scss";

export interface DescriptionsProps {
  descriptions: React.ReactNode[];
  useIcon?: boolean;
}

export function Descriptions({ descriptions, useIcon }: DescriptionsProps): React.ReactNode {
  return descriptions.map((description, index) => (
    <div key={index} className={clsx(styles.container, useIcon && styles.iconContainer)}>
      <div className={clsx(useIcon && styles.icon)}>
        {useIcon ? (
          <Image
            src="https://asset.basefood.co.jp/lp/form_02/check.png"
            alt=""
            size={{ width: 25, height: 25 }}
          />
        ) : (
          "・"
        )}
      </div>
      <div>{description}</div>
    </div>
  ));
}
