import { useEffect, useId, useRef } from "react";

import { debounce } from "throttle-debounce";

const buttonSelectors = {
  variant: ".anygift-variant-box__select-button__enabled",
  main: ".anygift-gift-ui__main-button",
  about: ".anygift-gift-ui__about-button__wrapper",
  cart: ".anygift-floating-cart",
} as const;

export const useButtonTextModifier = (type: "products" | "egiftlp", buttonId: string) => {
  const observerRef = useRef<MutationObserver | null>(null);
  const isModified = useRef<boolean>(false);

  useEffect(() => {
    // ボタンのテキストを変更する関数
    const modifyButtonTexts = () => {
      if (isModified.current) return;

      const container = document.querySelector(`[data-button-id="${buttonId}"]`);
      if (!container) return;

      if (type === "egiftlp") {
        const mainButton = container.querySelector(buttonSelectors.main);
        if (mainButton) {
          // 1つ目のボタンのみを変更
          mainButton.textContent = "eギフトで贈る";
        }

        const aboutWrap = container.querySelector(buttonSelectors.about);
        if (aboutWrap) {
          // 1つ目のボタンのみを変更
          aboutWrap.remove();
        }
      } else {
        const cartButton = document.querySelector(buttonSelectors.cart);
        if (cartButton) {
          // カートボタンを削除
          cartButton.remove();
        }
      }

      const variantButtons = document.querySelectorAll(buttonSelectors.variant);
      if (variantButtons.length === 0) return;

      variantButtons.forEach((button) => {
        button.textContent = "この商品を追加する";
      });

      isModified.current = true;
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };

    // 特定のコンテナ要素のみを監視
    const container = document.querySelector(`[data-button-id="${buttonId}"]`);
    if (!container) return;

    const mutationObserverCallback = debounce(60, (mutations: MutationRecord[]) => {
      for (const mutation of mutations) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          modifyButtonTexts();
          break;
        }
      }
    });

    observerRef.current = new MutationObserver(mutationObserverCallback);

    // 監視対象を特定のコンテナに限定
    observerRef.current.observe(container, {
      childList: true,
      // subtree: trueにすろと、Safariでの動作がかなり重くなるため、falseにしている
      subtree: false,
    });

    // 初回実行
    modifyButtonTexts();

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      isModified.current = false;
    };
  }, [type, buttonId]);
};

export const useButtonId = (defaultId?: string | undefined) => {
  const id = useId();
  return defaultId ?? `anygift-button-${id.replace(/\:/g, "")}`;
};
