export const productNameSkuMap = {
  basebread_plain: "102007",
  basebread_chocolate: "102008",
  basebread_maple: "102009",
  basebread_cinnamon: "102010",
  basebread_koshian: "102016",
  basebread_coffee: "102017",
  basebread_milk: "102018",
  basebread_strawberry: "102019",
  basebread_curry: "102011",
  basebread_slice_plain: "102012",
  basebread_slice_raisin: "102013",
  basebread_rich: "102014",

  basecookies_cocoa: "104003",
  basecookies_earlgrey: "104004",
  basecookies_matcha: "104005",
  basecookies_coconut: "104006",
  basecookies_pepper: "104008",
  basecookies_cheese: "104009",

  basepasta_asian: "101001",
  basepasta_fettochine: "101002",
  basepasta_bolognese: "111001",
  basepasta_tarako: "111002",
  basepasta_creamymushroom: "111003",
  basepancake_pancakemix: "105001",
  basepasta_sauceyakisoba: "111004",
  basepasta_umakaramazesoba: "111005",

  sauce_set: "103005",

  outlet_bolognese4: "200025",
  outlet_tarako4: "200026",
};
