import clsx from "clsx";

import { CartModel } from "@/models/cart/type";
import { formatCurrency } from "@/utils/currency";

import { ProductGroup } from "./ProductGroup";
import { ProductList } from "./ProductList";
import styles from "./ProductSection.module.scss";

interface ProductSectionProps {
  cart: CartModel;
}

export function ProductSection({ cart }: ProductSectionProps): React.ReactNode {
  const isFreezeDelivery = Boolean(cart.freezeProducts?.length);
  const isNormalDelivery = Boolean(cart.normalProducts?.length);

  return (
    <section className="pd__top__m">
      <div className="row">
        <div className="col-12">
          <p className="text__l text__bold mg__bottom__xs">ご注文内容</p>
          {isNormalDelivery && isFreezeDelivery ? (
            <div className="border__bottom">
              <ProductGroup cart={cart} />
              <ProductGroup cart={cart} isFreeze />
            </div>
          ) : (
            <ProductList products={cart.products ?? []} isFirstSubscription />
          )}

          <dl className={clsx("pd__bottom__s pd__top__m", styles.table)}>
            <dt className={styles.cell}>
              <p className="text__m">小計：</p>
            </dt>
            <dd className={clsx("text__right", styles.cell)}>
              <span className="text__l text__bold">{formatCurrency(cart.lineTotalPrice)}</span>
            </dd>
          </dl>

          {cart.couponDiscount > 0 && (
            <dl className="table__dl pd__bottom__s">
              <dt>
                <p className="text__m">値引き金額：</p>
              </dt>
              <dd className="text__right">
                {/*値引きなのでマイナスをつける*/}
                <span className="text__l text__bold">
                  {"- "}
                  {formatCurrency(cart.couponDiscount)}
                </span>
              </dd>
            </dl>
          )}

          <dl className={clsx("pd__bottom__s", styles.table)}>
            <dt className={styles.cell}>
              <p className="text__m">配送料：</p>
            </dt>
            <dd className={clsx("text__right", styles.cell)}>
              <span
                className={clsx("text__l text__bold", cart.totalShippingFee === 0 && "text__red")}
              >
                {formatCurrency(cart.totalShippingFee)}
                <sup className="sup">**</sup>
              </span>
            </dd>
          </dl>

          {cart.totalPriceCashDeliveryFee > 0 && (
            <dl className={clsx("pd__bottom__s border__bottom", styles.table)}>
              <dt className={styles.cell}>
                <p className="text__m">代引き手数料：</p>
              </dt>
              <dd className={clsx("text__right", styles.cell)}>
                <span className="text__l text__bold">
                  {formatCurrency(cart.totalPriceCashDeliveryFee)}
                  <sup className="sup">**</sup>
                </span>
              </dd>
            </dl>
          )}

          <dl className={clsx("mg__top__m", styles.table)}>
            <dt className={styles.cell}>
              <p className="text__m">合計：</p>
            </dt>
            <dd className={clsx("text__right", styles.cell)}>
              {cart.totalShippingFee > 0 ? (
                <span className="text__xxl text__bold">
                  {formatCurrency(cart.totalPrice + cart.totalPriceCashDeliveryFee)}
                </span>
              ) : (
                <>
                  <span className="text__s text__red">継続コース初回価格</span>
                  <br />
                  <span className="text__l text__bold text__red">
                    {formatCurrency(cart.totalPrice + cart.totalPriceCashDeliveryFee)}
                  </span>
                </>
              )}
            </dd>
          </dl>

          <div className="text__right">
            <p className="text__s">
              ※価格はすべて税込表示
              <br className="clear__pc" />
              *税率8% **税率10%
            </p>
            <p className="text__s">
              ※2回目以降のご購入から
              {formatCurrency(cart.afterSecondTotalPrice + cart.totalPriceCashDeliveryFee)}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
