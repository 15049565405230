"use client";

import clsx from "clsx";

import { FixedContainer } from "@/components/containers";
import { NextImageLP, Scroll } from "@/components/displays";
import { Button } from "@/components/inputs";

import ImgBtnChocolate from "$/images/lp/btn_chocolate.png";
import ImgBtnFloat from "$/images/lp/btn_float.png";

import { useIsVisible } from "./helper";
import styles from "./ScrollButton.module.scss";

const ScrollButtonPatterns = ["simple", "simple-contrast", "yellow", "green", "pink"] as const;

export type ScrollButtonPattern = (typeof ScrollButtonPatterns)[number];

interface ScrollButtonProps {
  pattern: ScrollButtonPattern;
  innerPaddingBottom?: boolean;
}

export function ScrollButton({ pattern, innerPaddingBottom }: ScrollButtonProps): React.ReactNode {
  const { isVisible } = useIsVisible();

  return (
    isVisible && (
      <FixedContainer
        id="product-fixed-section" // nuxtにあったid。どこで使用しているか不明
        className={clsx({
          bg__white: pattern === "simple",
          bg__yellow: pattern === "simple-contrast",
          // イメージじゃない場合、box-shadowをつける
          [styles.wrapper]: pattern === "simple" || pattern === "simple-contrast",
        })}
      >
        <Scroll
          to="lp-form"
          className={clsx(styles.inner, innerPaddingBottom && styles.innerPaddingBottom)}
        >
          {pattern === "simple" && (
            <Button variants="yellow" rounded className={clsx("text__m text__bold")}>
              {" 【Amazon Pay対応】30秒で購入 "}
              <i className={clsx("fas fa-angle-down", styles.icon)}></i>
            </Button>
          )}
          {pattern === "simple-contrast" && (
            <Button variants="gray" rounded className={clsx("text__m text__bold")}>
              {" 【Amazon Pay対応】30秒で購入 "}
              <i className={clsx("fas fa-angle-down", styles.icon)}></i>
            </Button>
          )}
          {pattern === "yellow" && (
            <NextImageLP src={ImgBtnChocolate} alt="ご購入はこちらから" width={750} />
          )}
          {pattern === "green" && (
            <NextImageLP src={ImgBtnFloat} alt="継続コースをお得にはじめる" />
          )}
        </Scroll>
      </FixedContainer>
    )
  );
}
