import { productNameSkuMap } from "./skuDB";

export const subscriptionProductAnnotation = [
  {
    sku: productNameSkuMap.basebread_koshian,
    annotation: [
      "※餡のみずみずしさや、生地の柔らかさを重視したため、配送中に他商品よりつぶれやすくなっております。何とぞご了承ください。",
    ],
  },
  {
    sku: productNameSkuMap.basebread_strawberry,
    annotation: [
      `※期間限定商品（2025年6月末まで）です。終売後は代替品として、同額のBASE BREADチョコレートをお届けします。\n※終了時期は目安です。在庫次第で販売終了いたします。`,
    ],
  },
];
