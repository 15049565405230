"use client";

import { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { Swiper as SwiperClass } from "swiper";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "../../../template/lp/Form01/styles/swipper.scss";
import { NextImageResponsive } from "@/components/displays";
import { FirstSpecialBenefitsSection } from "@/components/domains/lp/FirstSpecialBenefitsSection";
import { ProductsBySkuCalcPriceModel } from "@/models/product/type";

import ImgStartSetBox80 from "$/images/lp/startset_box_80.png";

import { SectionItem } from "./SectionItem";
import styles from "./StartSetSection.module.scss";

interface StartSetSectionProps {
  startSets: ProductsBySkuCalcPriceModel[];
  isWhiteTitle?: boolean;
  showSwiperHeader?: boolean;
}

export function StartSetSection({
  startSets,
  isWhiteTitle,
  showSwiperHeader,
}: StartSetSectionProps): React.ReactNode {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const slideTo = useCallback((index: number) => swiper?.slideTo(index), [swiper]);
  const [mounted, setMounted] = useState(false);

  /**
   * slidesPerViewを1以上いすると、JS側での計算は必要となり、mountedした後SwiperSlideにstyleが適用され、layout shiftが発生する
   * そのため、mountedした後のstyleと似ているstyleをSwiperSlideに適用することで、layout shiftを防ぐ
   */
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <section className={clsx("bg__yellow pd__top__xl pd__bottom__xl", styles.wrapper)}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-8 col-offset-m-2">
            <div className="text__center mg__bottom__l">
              <h3 className={clsx("text__xl text__bold", isWhiteTitle && "text__white")}>
                <span className={clsx("wsnr", isWhiteTitle && "text__m")}>はじめての方限定</span>{" "}
                <br />
                <span className="wsnr">継続コーススタートセット</span>
              </h3>
            </div>
          </div>
          <div className="col-12 col-m-4 col-offset-m-4 mg__bottom__l">
            <NextImageResponsive
              src={ImgStartSetBox80}
              width={308}
              alt="継続コーススタートセット"
            />
          </div>
        </div>

        <div className="clear__sp">
          <div className="row">
            {startSets.map((productSet, index) => (
              <SectionItem key={index} productSet={productSet} />
            ))}
          </div>
        </div>
        {showSwiperHeader && (
          <div className="row">
            <div className="col-12 mg__bottom__l clear__pc">
              <div className={styles.toggle}>
                {startSets.map((productSet, index) => (
                  <span
                    className={clsx(
                      "text__s text__bold",
                      styles.button,
                      activeIndex === index && styles.active
                    )}
                    key={index}
                    onClick={() => slideTo(index)}
                  >
                    {productSet.quantity}袋セット
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="fix-container-margin">
          <Swiper
            // SwiperSlideにおいて、mountの前styleがでキャッシュされている？可能性があり、
            // mount後にステートをリフレッシュするため、keyを変更する
            key={mounted ? "mounted" : "unmounted"}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className={clsx("clear__pc")}
            spaceBetween={20}
            slidesPerView={1.2}
            centeredSlides
            onSwiper={setSwiper}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex);
            }}
          >
            {startSets.map((productSet, index) => (
              <SwiperSlide key={index}>
                <SectionItem productSet={productSet} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <FirstSpecialBenefitsSection />
      </div>
    </section>
  );
}
