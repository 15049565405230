"use client";

import { useButtonId, useButtonTextModifier } from "./helper";

interface AnygiftButtonProps {
  productCode: string;
  type: "products" | "egiftlp";
}

export const AnygiftButton: React.FC<AnygiftButtonProps> = ({
  productCode,
  type,
}): React.ReactElement => {
  const buttonId = useButtonId();
  useButtonTextModifier(type, buttonId);

  return <div data-anygift data-anygift-product-code={productCode} data-button-id={buttonId} />;
};
