import clsx from "clsx";

import { Image, MoneySpan } from "@/components/displays";
import { SelectedProducts } from "@/components/domains/lp/OnePageCheckoutForm/SetSelector/types";
import { Selector } from "@/components/inputs";
import { subscriptionProductAnnotation } from "@/configs/products";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { CartModel } from "@/models/cart/type";
import { ProductModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./GroupedProduct.module.scss";

interface GroupedProductProps {
  cartInfo?: CartModel;
  groupedProducts: Record<string, ProductModel[] | undefined>;
  selectedProducts: SelectedProducts;
  onChange: (variantId: number) => (value: number) => void;
  onClickDelete: (variantId: number) => () => void;
  isFreeze?: boolean;
}

export function GroupedProduct({
  cartInfo,
  groupedProducts,
  selectedProducts,
  onChange,
  onClickDelete,
  isFreeze,
}: GroupedProductProps): React.ReactNode {
  const validPurchase = isFreeze ? cartInfo?.validFreezePurchase : cartInfo?.validPurchase;

  return (
    <div
      className={clsx("mg__top__m mg__bottom__m", isFreeze ? styles.freezeBox : styles.normalBox)}
    >
      <p
        className={clsx(
          "text__m text__bold text__white",
          isFreeze ? styles.freezeTitle : styles.normalTitle
        )}
      >
        {isFreeze ? "冷凍配送" : "常温配送"}
      </p>

      {Object.entries(groupedProducts).map(([label, products = []]) => (
        <section key={label} className="mg__top__m">
          <p className="text__m text__bold">{label}</p>
          {products.map((product) => {
            const targetProductAnnotation = subscriptionProductAnnotation.find(
              ({ sku }) => sku === product.sku
            );
            return (
              <div key={product.variantId} className={styles.productBorder}>
                <div className={clsx("pd__top__m", styles.flex)}>
                  <div>
                    <Image
                      src={product.images[product.variantImageIndex]}
                      alt={product.title}
                      className={styles.productThumbnail}
                      size={{ width: 80, height: 80 }}
                    />
                  </div>
                  <div className={styles.product}>
                    <p className="text__m">{product.title}</p>
                    {product.meal !== "組" && <p className="text__m">{product.variantTitle}</p>}
                    <p className="mg__top__s">
                      <span className={styles.discountTag}>初回割引</span>
                      <span className="wsnr">
                        <MoneySpan
                          className="text__red"
                          priceClassName="text__m  text__bold"
                          suffix={`/${product.meal}`}
                          suffixClassName="text__s"
                        >
                          {product.firstSubscriptionPrice}
                        </MoneySpan>
                      </span>
                    </p>
                  </div>
                </div>
                <div className={clsx("pd__bottom__m", styles.flex, styles.flexCenter)}>
                  <div className={styles.selector}>
                    <Selector
                      // 選択されていない場合、数量を「0」にする
                      value={selectedProducts[product.variantId] || 0}
                      onChange={onChange(product.variantId)}
                      options={product.selectOptions.map((option) => ({
                        label: option.toString(),
                        value: option,
                      }))}
                    />
                  </div>
                  <span className="text__m">{product.meal}</span>
                  {!!selectedProducts[product.variantId] && (
                    <button
                      className="btn inline mini round gray text__black text__bold"
                      onClick={onClickDelete(product.variantId)}
                    >
                      削除
                    </button>
                  )}
                  <MoneySpan suffix="*" className={clsx("text__l text__bold", styles.flexRight)}>
                    {(selectedProducts[product.variantId] ?? 0) * product.firstSubscriptionPrice}
                  </MoneySpan>
                </div>
                {targetProductAnnotation?.annotation && (
                  <p
                    className={clsx(
                      "text__s text__gray__dark pd__bottom__m",
                      styles.productAnnotation
                    )}
                  >
                    {targetProductAnnotation.annotation}
                  </p>
                )}
              </div>
            );
          })}
        </section>
      ))}

      {!validPurchase && (
        <div className={clsx("mg__top__m", styles.cartError)}>
          <p className="text__m text__center text__white">
            {`合計金額${formatCurrency(isFreeze ? PRODUCT_SALE_PARAMETER.minDeliveryAmountFrozen : PRODUCT_SALE_PARAMETER.minDeliveryAmountNormal).replace("¥", "")}円以上から`}
            <br />
            ご購入いただけます。
          </p>
        </div>
      )}

      <dl className={clsx("mg__top__s", styles.totalPriceTable)}>
        <dt className="text__left">
          <span className="text__m">小計：</span>
        </dt>
        <dd className="text__right">
          <MoneySpan className="text__l text__bold text__red">
            {isFreeze ? cartInfo?.freezeProductTotalPrice : cartInfo?.normalProductTotalPrice}
          </MoneySpan>
        </dd>
      </dl>
      <dl className={clsx("mg__top__s", styles.totalPriceTable)}>
        <dt className="text__left">
          <span className="text__m">配送料：</span>
        </dt>
        <dd className="text__right">
          <MoneySpan
            className={clsx(
              "text__l text__bold",
              (isFreeze ? !cartInfo?.freezeShippingFee : !cartInfo?.normalShippingFee) &&
                "text__red"
            )}
            suffix="**"
          >
            {isFreeze ? cartInfo?.freezeShippingFee : cartInfo?.normalShippingFee}
          </MoneySpan>
        </dd>
      </dl>
    </div>
  );
}
