"use client";

import React, { useCallback } from "react";

import { NextImageLP, Scroll } from "@/components/displays";

import ImgMaru14Btn from "$/images/lp/btn_set14_maru.png";
import ImgMaru28Btn from "$/images/lp/btn_set28_maru.png";
import ImgMaru46Btn from "$/images/lp/btn_set46_maru.png";
import ImgMaru14 from "$/images/lp/set14_maru.png";
import ImgMaru28 from "$/images/lp/set28_maru.png";
import ImgMaru46 from "$/images/lp/set46_maru.png";

import styles from "./SetDetail.module.scss";
import { useLPStore } from "../../store";

export interface SetDetailProps {
  bags: 14 | 28 | 46;
  lp?: "gothic" | "maru" | "form";
}

const imageMap = {
  14: {
    bg: ImgMaru14,
    btn: ImgMaru14Btn,
  },
  28: {
    bg: ImgMaru28,
    btn: ImgMaru28Btn,
  },
  46: {
    bg: ImgMaru46,
    btn: ImgMaru46Btn,
  },
};

export function SetDetail({ bags }: SetDetailProps): React.ReactNode {
  const { setSelectIndex } = useLPStore();

  const handleSetSelectIndex = useCallback(() => {
    let index = 1;
    switch (bags) {
      case 14:
        index = 0;
        break;
      case 46:
        index = 2;
        break;
    }

    setSelectIndex(index);
  }, [bags, setSelectIndex]);

  return (
    <div className={styles.wrapper}>
      {/* TODO: magic number -8を消す */}
      <NextImageLP src={imageMap[bags].bg} alt={`${bags}袋セット`} style={{ marginBottom: -8 }} />
      <Scroll to="lp-form" onClick={handleSetSelectIndex} className={styles.cvButton}>
        <NextImageLP src={imageMap[bags].btn} alt={`${bags}食セットを購入`} />
      </Scroll>
    </div>
  );
}
