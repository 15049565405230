import { useCallback, useEffect, useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { DeepPartial, FormProvider, useForm } from "react-hook-form";

import { Button, PanelSelector, SelectorOption } from "@/components/inputs";
import { OKIHAI_MAX_PRICE } from "@/configs/system";
import { CartModel } from "@/models/cart/type";
import { DeliveryReceiveOption } from "@/models/delivery/consts";
import { PaymentMethod } from "@/models/payment/consts";
import { useClientCartForm } from "@/storage";
import { fireAmazonPayAction, setFormDataForAmazonPay } from "@/utils/amazon";
import { objectMerge } from "@/utils/object";

import styles from "./CheckoutForm.module.scss";
import { ConfigButton } from "./ConfigButton/ConfigButton";
import { Controller } from "./Controller";
import { FormSection } from "./FormSection/FormSection";
import { FormTitle } from "./FormTitle/FormTitle";
import { CheckoutFormValues } from "./schema";
import { convertFormValuesToStorage } from "./utils";
import { useAmazonPay } from "../../amazon";
import { CouponCheckbox } from "../../lp/OnePageCheckoutForm/CheckoutConfirmation/CouponCheckbox";
import { AddressForm } from "../AddressForm";
import { AddressFormForAmazonPay } from "../AddressFormForAmazonPay/AddressFormForAmazonPay";
import { DeliveryDateSelector } from "../DeliveryDateSelector";
import { PaymentMethodSelector } from "../PaymentMethodSelector";

export interface CheckoutFormProps {
  cart: CartModel | undefined;
  isLoggedIn: boolean; // ログインしているかどうか、（Email、Password,acceptsMarketingが入力不可になる）
  showLogin: boolean; //  ログインしていない場合に、ログインを表示するかどうか（ログインしている場合に、ログアウトボタンを表示するかどうか）
  showCoupon: boolean;
  showBackButton: boolean; // '/cart'に戻るので注意
  confirmButtonVariants: "yellow" | "blue";
  hiddenForm: boolean; // フォームを下半分に隠すかどうか
  hidePaymentSection?: boolean; // 支払い方法を隠す
  isSubmittable?: boolean;
  defaultValues: DeepPartial<CheckoutFormValues>;
  onChange: (
    value: CheckoutFormValues,
    event: { name: keyof CheckoutFormValues; type: string | undefined }
  ) => void;
  onConfirm: (formValues: CheckoutFormValues) => void;
  onChangedEmail?: (email: string) => void;
  deliveryDateOptions: SelectorOption<string>[];
  hideFooterAnnotation?: boolean;
  // LPの場合は、請求先住所を選択するセレクトボックスを非表示にする
  hideBillingAddressSelect?: boolean;
  confirmButtonText?: string;
}

export function CheckoutForm({
  cart,
  isLoggedIn,
  showLogin,
  showBackButton,
  showCoupon,
  confirmButtonVariants,
  isSubmittable = true,
  defaultValues,
  hiddenForm,
  hidePaymentSection,
  onChange,
  onConfirm,
  onChangedEmail,
  deliveryDateOptions,
  hideFooterAnnotation,
  hideBillingAddressSelect,
  // checkoutページはデフォルト値、LPは `注文内容を確認する` となる
  confirmButtonText = "ご注文を確認する",
}: CheckoutFormProps): React.ReactNode {
  const { amazonPayEnable, amazonPayCheckoutSessionId, amazonPayCheckoutData } = useAmazonPay();
  const formContext = useForm<CheckoutFormValues>({
    defaultValues,
  });
  const { setForm } = useClientCartForm();
  const router = useRouter();

  const [isFreezeDelivery, isNormalDelivery] = useMemo(() => {
    return [
      (cart?.freezeProducts?.length ?? 0) > 0,
      (cart?.normalProducts?.length ?? 0) > 0,
    ] as const;
  }, [cart]);

  useEffect(() => {
    if (!onChange) return;
    const { watch } = formContext;
    const subscription = watch((value, { name, type }) => {
      if (type === undefined && name === undefined) {
        // typeとnameが両方undefinedの場合は、初期値の設定時に発火するため、無視する
        return;
      }
      onChange(value as CheckoutFormValues, {
        name: name as keyof CheckoutFormValues,
        type,
      });
    });
    // コンポーネントのアンマウント時に購読を解除
    return () => subscription.unsubscribe();
  }, [formContext, onChange]);

  const shippingAddress = formContext.watch("shippingAddress");

  const handleChangeAmazonPayAddress = useCallback(() => {
    if (amazonPayCheckoutSessionId) {
      setFormDataForAmazonPay(
        "amazon_pay_input_data",
        convertFormValuesToStorage(formContext.getValues())
      );
      fireAmazonPayAction(amazonPayCheckoutSessionId, "changeAddress");
    }
  }, [amazonPayCheckoutSessionId, formContext]);

  const handleChangeAmazonPayPayment = useCallback(() => {
    if (amazonPayCheckoutSessionId) {
      setFormDataForAmazonPay(
        "amazon_pay_input_data",
        convertFormValuesToStorage(formContext.getValues())
      );
      fireAmazonPayAction(amazonPayCheckoutSessionId, "changePayment");
    }
  }, [amazonPayCheckoutSessionId, formContext]);

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm(formContext.getValues());
    }
  }, [formContext, onConfirm]);

  const handleReturnToCart = useCallback(() => {
    setForm((prev) => objectMerge(prev, convertFormValuesToStorage(formContext.getValues())));
    router.push("/cart");
  }, [formContext, router, setForm]);

  const deliveryReceiveOption = formContext.watch("deliveryOptions.deliveryReceiveOption");
  const paymentMethod = formContext.watch("paymentData.paymentMethod");
  const isDaibiki = paymentMethod === PaymentMethod.daibiki;
  const exceededMaxPrice = cart && cart.totalPrice >= OKIHAI_MAX_PRICE;
  const disabledReceiveOptionReason = isDaibiki
    ? "支払い方法が代金引換のため置き配が選択できません"
    : exceededMaxPrice
      ? "合計金額が¥150,000(税込)以上のため置き配が選択できません"
      : isFreezeDelivery
        ? "冷凍配送の場合、置き配をご利用いただけません"
        : undefined;

  const isOkihai = deliveryReceiveOption !== DeliveryReceiveOption.FaceToFace;
  const containsOutlet = cart?.products.some((product) => product.isOutlet);

  return (
    <FormProvider {...formContext}>
      <form>
        <FormTitle>お客様情報を入力して購入する</FormTitle>
        {!amazonPayEnable && showLogin && !isLoggedIn && (
          <div className="row">
            <div className={clsx("col-12", "col-m-7")}>
              <p className={clsx("text__s", "mg__bottom__s", "text__justify")}>
                すでにBASEFOODのアカウントをお持ちの方はこちらからにログインしてご購入いただけます。
              </p>
            </div>
            <div
              className={clsx(
                "col-12",
                "col-m-5",
                "text__center",
                "arrow__right",
                "pd__top__s",
                "pd__top__off__pc"
              )}
            >
              <Link
                href={`/account/login?redirect_checkout=1`}
                className={clsx(
                  "btn",
                  "inline",
                  "yellow",
                  "round",
                  "angle__right",
                  styles.loginLink
                )}
              >
                ログイン
                <i className={clsx("fas", "fa-angle-right")} />
              </Link>
            </div>
          </div>
        )}

        <p className="text__s mg__top__m mg__bottom__l">
          以下のフォームからお客様情報・支払情報を入力して商品をご購入いただけます。
        </p>
        <div className={clsx(styles.formHidden, !hiddenForm && styles.formOpen)}>
          <FormSection title="お客様情報">
            {!amazonPayEnable ? (
              <Controller
                name="shippingAddress"
                render={({ field: addressFields }) => (
                  <Controller
                    name="credentials"
                    render={({ field: credentialsFields }) => {
                      return (
                        <AddressForm
                          isLoggedIn={isLoggedIn}
                          address={addressFields.value}
                          onChangeAddress={addressFields.onChange}
                          credentials={credentialsFields.value}
                          onChangeCredentials={credentialsFields.onChange}
                          // ログインがあるということはログアウトボタンも表示する
                          showLogout={showLogin}
                          withCredentials
                          onChangedEmail={onChangedEmail}
                        />
                      );
                    }}
                  />
                )}
              />
            ) : (
              <Controller
                name="shippingAddress"
                render={({ field: addressFields }) => (
                  <Controller
                    name="credentials"
                    render={({ field: credentialsFields }) => {
                      return (
                        <AddressFormForAmazonPay
                          address={addressFields.value}
                          showPasswordInput={!isLoggedIn}
                          onClickChangeAddress={handleChangeAmazonPayAddress}
                          credentials={credentialsFields.value}
                          onChangeCredentials={credentialsFields.onChange}
                        />
                      );
                    }}
                  />
                )}
              />
            )}
          </FormSection>
          {!hideBillingAddressSelect && (
            <FormSection title="請求先住所">
              <Controller
                name="billingAddressSelect"
                render={({ field: { value, onChange } }) => (
                  <PanelSelector
                    value={value}
                    onChange={onChange}
                    options={[
                      {
                        title: "配送先住所と同じ",
                        value: false,
                      },
                      {
                        title: "違う請求先住所を使う",
                        value: true,
                        content: (
                          <Controller
                            name="billingAddress"
                            render={({ field: { value, onChange } }) => (
                              <AddressForm
                                showLogout={false}
                                withCredentials={false}
                                address={value}
                                onChangeAddress={onChange}
                              />
                            )}
                          />
                        ),
                      },
                    ]}
                  />
                )}
              />
            </FormSection>
          )}
          <FormSection title="配送日指定">
            <Controller
              name="deliveryOptions"
              render={({ field: { value, onChange } }) => (
                <DeliveryDateSelector
                  onChangeDeliveryOptions={onChange}
                  deliveryOptions={value}
                  province={shippingAddress?.province}
                  disabledReceiveOption={isDaibiki || exceededMaxPrice || isFreezeDelivery}
                  disabledReceiveOptionReason={disabledReceiveOptionReason}
                  isSubscription={cart?.isSubscription}
                  isFreezeDelivery={isFreezeDelivery}
                  isNormalDelivery={isNormalDelivery}
                  deliveryDateOptions={deliveryDateOptions}
                />
              )}
            />
          </FormSection>
          {!hidePaymentSection && (
            <FormSection
              title="支払い方法"
              subText="すべての取引は安全であり、暗号化されています。"
              action={
                amazonPayEnable && (
                  <ConfigButton onClick={handleChangeAmazonPayPayment}>変更</ConfigButton>
                )
              }
            >
              {amazonPayEnable ? (
                <p className={clsx("text__m", "mg__bottom__s")}>
                  {amazonPayCheckoutData.paymentPreferences}
                </p>
              ) : (
                <Controller
                  name="paymentData"
                  render={({ field: { value, onChange } }) => (
                    <PaymentMethodSelector
                      onChangePaymentData={onChange}
                      paymentData={value}
                      showConvenience={!cart?.isSubscription && !containsOutlet}
                      showBankTransfer={!cart?.isSubscription && !containsOutlet}
                      showDaibiki
                      // 置き配の場合は代引きは利用不可
                      daibikiDisabled={isOkihai}
                    />
                  )}
                />
              )}
            </FormSection>
          )}
          {showCoupon && (
            <FormSection title="その他">
              <Controller
                name="coupon"
                render={({ field: { value, onChange } }) => (
                  <CouponCheckbox coupon={value} onChange={onChange} />
                )}
              />
            </FormSection>
          )}
          <div className="row">
            <div className={clsx("mg__top__s", "col-12", "text__center", "pd__bottom__l")}>
              {showBackButton && (
                <button
                  className={clsx("btn", "inline", "gray", "round", "text__m")}
                  type="button"
                  onClick={handleReturnToCart}
                >
                  戻る
                </button>
              )}
              <Button
                variants={confirmButtonVariants}
                className="btn round"
                disabled={!isSubmittable}
                // react-hook-formのSubmitのバリデーションは現在利用していないため
                // ボタンのクリックイベントでサブミット処理を行う
                onClick={handleConfirm}
                type="button"
                arrowRight
              >
                {confirmButtonText}
                <i className="fas fa-angle-right" />
              </Button>
              {!hideFooterAnnotation && (
                <p className={clsx("text__s", "mg__top__m")}>
                  読込に時間がかかる場合がございます。
                  <br className="clear__pc" />
                  画面を閉じずにそのままお待ちください。
                </p>
              )}
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
