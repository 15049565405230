"use client";

import { LineupSectionItem } from "@/components/domains/lp/LineupSection/LineupSectionItem";
import { LineupSectionItemSp } from "@/components/domains/lp/LineupSection/LineupSectionItemSp";

import { items } from "./consts";

interface LineupSectionProps {}

export function LineupSection({}: LineupSectionProps): React.ReactNode {
  return (
    <section>
      <div className="bg__yellow pd__top__xl pd__bottom__xl clear__sp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text__center">
                <h2 className="text__xl text__bold">ラインナップ</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {items.map((item) => (
              <div className="col-12 col-m-3 pd__top__l" key={item.title}>
                <LineupSectionItem {...item} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg__yellow pd__top__xl pd__bottom__xl clear__pc">
        <div className="container">
          <div className="row">
            <div className="col-12 col-m-8 col-offset-m-2">
              <div className="text__center mg__bottom__l">
                <h2 className="text__xl text__bold">ラインナップ</h2>
              </div>
              {items.map((item) => (
                <LineupSectionItemSp key={item.title} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
