import React from "react";

import clsx from "clsx";

import { LinkConfirm } from "@/components/displays";

import styles from "./Breadcrumb.module.scss";

type BreadcrumbItem = {
  path: string;
  title: string;
};

export type BreadcrumbProps = {
  current: string;
  items?: BreadcrumbItem[];
  textClassName?: string;
};

export const Breadcrumb = ({ current, items, textClassName }: BreadcrumbProps) => {
  return (
    <div id="bf-breadcrumb" className={clsx("row mg__bottom__m mg__top__m", styles.root)}>
      <div className="col-12">
        <p className="text__s">
          <i className={clsx("fas fa-angle-right", styles.arrow)} />
          {items?.map((_item, index) => {
            return (
              <React.Fragment key={index}>
                <LinkConfirm href={_item.path}>
                  {/* CSSの適用順の問題を解消するために、textClassNameが渡された場合、text__yellowを適用しないようにする */}
                  <span className={clsx(textClassName ? textClassName : "text__yellow")}>
                    {_item.title}
                  </span>
                </LinkConfirm>
                <i className={clsx("fas fa-angle-right", styles.arrow)} />
              </React.Fragment>
            );
          })}
          <span className={clsx(items?.length && "text__red")}>{current}</span>
        </p>
      </div>
    </div>
  );
};
