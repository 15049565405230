import { forwardRef } from "react";

import { Row } from "@/components/containers";
import { TooltipIcon } from "@/components/displays";
import { Checkbox, CheckboxProps } from "@/components/inputs";

export interface MarketingCheckboxProps extends Omit<CheckboxProps, "children"> {
  tooltipId: string;
}

export const MarketingCheckbox = forwardRef<HTMLInputElement, MarketingCheckboxProps>(
  function MarketingCheckbox({ tooltipId, ...rest }, ref) {
    return (
      <Row align="center">
        <Checkbox labelNoMargin {...rest} ref={ref}>
          ベースフードからのお知らせを受け取る
        </Checkbox>
        <TooltipIcon
          tooltipTitle="お知らせの内容"
          tooltipCloseButtonText="OK"
          tooltips={
            <p className="text__m">
              お得で便利な情報をお届けします。
              <br />
              ・新商品試食会へのご案内
              <br />
              ・限定割引クーポン
              <br />
              ・おいしい食べ方など
            </p>
          }
          pcTooltips={`お得で便利な情報をお届けします。\n・新商品試食会へのご案内\n・限定割引クーポン\n・おいしい食べ方など`}
        />
      </Row>
    );
  }
);
