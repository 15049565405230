import React from "react";

import clsx from "clsx";

import { NextImageLP } from "@/components/displays";

import ImgSafetyBanner from "$/images/lp/safety_banner.jpg";

import styles from "./SafetyBanner.module.scss";

export interface SafetyBannerProps {
  width?: number;
}

export function SafetyBanner({ width = 750 }: SafetyBannerProps): React.ReactNode {
  return (
    <section className="pd__top__l pd__bottom__l">
      <a href="https://basefood.co.jp/safety" target="_blank" rel="noopener noreferrer">
        <NextImageLP
          src={ImgSafetyBanner}
          // イメージは920px幅で作成しているため、750pxに強制リサイズ
          width={width}
          alt="安心・安全の取り組み"
          className={clsx(styles.bnrBorder)}
        />
      </a>
    </section>
  );
}
