import clsx from "clsx";
import Image, { ImageProps } from "next/image";

import styles from "./NextImageLP.module.scss";

export interface NextImageLPProps extends ImageProps {}

export function NextImageLP({
  className,
  alt,
  src,
  ...restProps
}: NextImageLPProps): React.ReactNode {
  return (
    <Image
      alt={alt}
      src={src}
      className={clsx(styles.imageLP, className)}
      sizes={`(max-width: 749px) 100vw, 750px`}
      {...restProps}
    />
  );
}
